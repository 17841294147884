<template>
  <main>
    <div class="container">
      <div class="alert alert--danger" v-if="error" v-html="error"></div>
      <div class="exchange-form">
        <div class="exchange-form__loading" v-if="loading">
          Идёт загрузка<span class="primary-text">..</span><span class="secondary-text">.</span>
        </div>
        <slot name="pre-text"></slot>
        <div class="exchange-form__products-list products-list" v-if="!loading && filtered.length > 0">
        </div>
        <div class="exchange-form__products-list products-list" v-if="!loading && filtered.length === 0">У нас технический сбой. Уже чиним. Выбор подарков будет доступен совсем скоро.</div>
      </div>
    </div>
  </main>
</template>

<script>
import Vue from 'vue'
import catalog from 'base/api/catalog'
import MicroModal from 'micromodal'
import appError from 'base/modules/app-error'
import Swal from 'sweetalert2'

const storeModule = {
  namespaced: true,
  state: {
    products: []
  },
  mutations: {
    updateProducts: function (state, payload) {
      state.products = payload
    }
  },
  actions: {
    setProducts ({ commit }, payload) {
      commit('updateProducts', payload)
    }
  }
}

export default {
  name: 'Catalog',
  data () {
    return {
      price: null,
      exchangeProducts: [],
      loading: true,
      error: ''
    }
  },
  computed: {
    filtered () {
      if (this.$store.state.catalog.products.length > 0) {
        return this.$store.state.catalog.products.filter(product => {
          return (typeof product.meta.pricelist !== 'undefined' && product.meta.pricelist !== null && product.meta.pricelist.length > 0)
        })
      } else {
        return []
      }
    }
  },
  methods: {
    getCatalog: function () {
      return catalog
    },
    getHashValue: function (key) {
      const matches = location.hash.match(new RegExp(key + '=([^&]*)'))
      return matches ? matches[1] : null
    },
    closeModal: function (modalId) {
      MicroModal.close(modalId)
    },
    showExchangeProducts: function (id) {
      catalog.getExchangeProductsById(this, id).then(response => {
        this.error = ''
        this.exchangeProducts = response.products.sort((a, b) => a.sort - b.sort)
        this.$nextTick(() => {
          MicroModal.show('exchange-products', {
            onShow: modal => {
              document.body.style.position = 'fixed'
              document.body.style.width = '100%'
            },
            onClose: modal => {
              document.body.style.position = 'initial'
              document.body.style.width = 'auto'
            },
            openTrigger: 'data-micromodal-open',
            closeTrigger: 'data-micromodal-close',
            disableScroll: true,
            disableFocus: false,
            awaitCloseAnimation: false,
            debugMode: true
          })
        })
      }).catch((error) => {
        this.error = appError.getMessage(error.message)
        this.data = {}
        Vue.$logger.debug('Catalog | order.getExchangeProductsById | ошибка: ', error)
        this.$nextTick(() => {
          // this.showModal()
        })
      })
    },
    addToCart: function (product, price) {
      this.price = product.meta.pricelist[0]
      this.beforeAddToCart(product.id, price).then(() => {
        this.$store.dispatch('cart/add', {
          id: product.id,
          qty: 1,
          price: price
        })
        this.afterAddToCart(product.id, price)
      }).catch(function () {
        Vue.$logger.error('addToCart | ошибка')
      })
    },
    beforeAddToCart: function (productId, price) {
      Vue.$logger.debug('Catalog | Before add to cart from Component')
      return new Promise((resolve, reject) => {
        if (price.points > this.$store.state.user.balance) {
          Swal.fire({
            title: 'Недостаточно баллов',
            html: 'Вашего баланса недостаточно для&nbsp;добавления в&nbsp;корзину',
            type: 'error',
            confirmButtonText: 'Закрыть',
            confirmButtonClass: 'button button--rounded button--large button--margined',
            buttonsStyling: false
          })
          reject(new Error('beforeAddToCart | ошибка'))
        } else {
          this.$store.dispatch('cart/clear')
          resolve()
        }
      })
    },
    afterAddToCart: function () {
      Vue.$logger.debug('Catalog | After add to cart from Component')
      return new Promise((resolve, reject) => {
        this.$router.push('/order').catch(()=>{})
        resolve()
      })
    },
    afterMounted: function () {
      this.getCatalog().get(this).then(catalog => {
        this.loading = false

        const products = catalog.products.map(item => {
          item.accepted = false
          item.disclaimerVisible = true
          return item
        })

        this.$store.dispatch('catalog/setProducts', products)

        this.addToCart(this.filtered[0], this.filtered[0].meta.pricelist[0])
      }).catch((error) => {
        this.loading = false

        this.error = appError.getMessage(error.message)

        Vue.$logger.debug('Catalog | catalog.get | ошибка: ', error)
      })
    },
    hasFlexible (product) {
      return product.meta.flexible
    }
  },
  created: function () {
    if (typeof this.$store._modules.root._children.catalog === 'undefined') {
      this.$store.registerModule('catalog', storeModule)
    }
  },
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--catalog')
    this.afterMounted()
  }
}
</script>

<style>
.product .modal__wrapper,
#exchange-products .modal__wrapper {
  margin-bottom: 0 !important;
}

.product .modal__header,
#exchange-products .modal__header {
  justify-content: right !important;
  margin: -10px 0 !important;
}

@media (min-width: 768px) {
  .product .modal__header,
  #exchange-products .modal__header {
    margin: -30px !important;
  }

  .product .modal__close,
  #exchange-products .modal__close {
    font-size: 2rem !important;
    line-height: 0 !important;
    width: 32px !important;
    height: 32px !important;
  }
}
</style>

<style lang="css" scoped>
  .exchange-form {
    padding: 20px 0;
  }

  .banner-text {
    margin: 1em 0;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    color: rgb(235, 90, 64);
  }

  @media (min-width: 768px) {
    .banner-text {
      font-size: 30px;
    }
  }

  @media (min-width: 992px) {
    .banner-text {
      font-size: 34px;
    }
  }

  .product__column {
    margin-bottom: 30px;
  }

  .card-image {
    max-width: 100%;
    box-shadow: 0 10px 25px -5px rgba(0,0,0,.2);
  }

  .product {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background: #fff;
    box-shadow: 0 10px 25px -5px rgba(0,0,0,.2);
  }

  .product__image {
    position: relative;
    z-index: 1;
    padding-top: 45.59%;
    height: 0;
    overflow: hidden;
    border-radius: var(--border-radius);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .product__image img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .product__description {
    padding: 20px;
    font-size: 16px;
    color: #333;
  }

  .product-exchange {
    display: block;
    color: #333;
    text-decoration: none;
    text-align: center;
    background: #fff;
    box-shadow: 0 10px 25px -5px rgba(0,0,0,.2);
    margin-bottom: 20px;
  }

  .product-exchange:hover {
    color: var(--accent-color);
  }

  .product-exchange__image {
    position: relative;
    z-index: 1;
    padding-top: 45.59%;
    height: 0;
    overflow: hidden;
    border-radius: var(--border-radius);
  }

  .product-exchange__image img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .product-exchange__title {
    padding: 10px;
    background: #f8f8f8;
  }

  .modal__container {
    max-width: 1100px;
  }

  .modal__title {
    text-transform: none;
    font-weight: normal;
  }

  .product__buttons {
    margin-top: auto;
    display: flex;
    padding: 0 20px 20px;
  }

  .product__exchange-modal-link span {
    color: var(--main-color);
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }

  .product__exchange-modal-link span:hover {
    color: var(--accent-color);
    text-decoration: none;
  }
</style>
